<template>
  <v-card class="mx-n6">
    <v-card-title class="justify-center">
      <span class="headline">Create schedule</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row class="justify-center" dense>
            <span class="text-h6 mb-6">{{ worker.username }}</span>

          <v-row>
            <v-col cols="12" md="5" offset="0" offset-sm="1" offset-md="0">
              <v-time-picker v-model="fromTime" elevation="2" color="green"
                             ampm-in-title format="ampm" landscape scrollable></v-time-picker>
            </v-col>
            <v-col cols="12" md="5" offset="0" offset-sm="1">
              <v-time-picker v-model="toTime" elevation="2" color="deep-orange darken-1"
                             ampm-in-title format="ampm" landscape scrollable></v-time-picker>
            </v-col>
          </v-row>

          <v-row class="justify-center mt-2">
            <v-col cols="6">
              <v-textarea name="input-7-4" label="Note" v-model="schedule.comment" rows="4"
                          background-color="amber lighten-4" class="mt-n3"></v-textarea>
            </v-col>
          </v-row>
        </v-row>
        <v-col cols="12" v-if="errorMessage">
          <v-alert text type="error">{{ errorMessage }}</v-alert>
        </v-col>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="grey darken-1" class="mb-5 mt-2 px-8" outlined @click="closeDialog">Cancel</v-btn>
      <v-btn color="blue darken-1" class="mb-5 mt-2 px-12 ml-10" outlined @click="saveSchedule">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ScheduleService from "@/service/ScheduleService";
import {ScheduleInsert} from "@/model/schedule/ScheduleInsert";

export default {
  name: "NewScheduleAdminDialog",
  props: {
    worker: {
      type:  Object,
      required: true
    }
  },

  data() {
    return {
      selectedClient: this.client,
      fromTime: null,
      toTime: null,
      schedule: {
        "workerId": null,
        "startedAt": null,
        "endedAt": null,
        "comment": ''
      },
      selectedGroupIdUpdate: null,
      errorMessage: null
    }
  },

  methods: {
    closeDialog(arg) {
      this.updateClientErrorMessage = '';
      this.$emit('closeDialog', arg);
    },

    async saveSchedule() {
      this.showOverlay = true;
      this.errorMessage = "";
      if (!this.fromTime) {
        this.errorMessage = "Choose starting time!";
        return;
      }
      if (!this.toTime) {
        this.errorMessage = "Choose ending time!";
        return;
      }
      //todo: check if toTime is < than fromTime
      try {
        await ScheduleService.insert(new ScheduleInsert(this.worker.id, this.fromTime, this.toTime,
            this.schedule.comment));
        this.closeDialog();
        this.$emit('closeDialog', {"workerId": this.worker.id});
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.showOverlay = false;
      }
    }
  }

}
</script>
