<template>
  <v-container>
    <v-row align="center" justify="center">
      <!-- error happened -->
      <v-row align="center" justify="center" v-if="errorMessage">
        <v-col cols="5">
          <v-alert type="error" dismissible>{{ errorMessage }}</v-alert>
        </v-col>
      </v-row>

      <!--show waiting schedules if any-->
      <v-row justify="center" v-if="schedules.length > 0" class="mt-6">
        <v-col cols="10" xs="12" md="6" v-for="s in schedules" :key="s.id">
          <ScheduleWorkerCard :schedule="s" :key="s.id" @onConfirm="remove" @onCancel="remove"/>
        </v-col>
      </v-row>
      <!--else show notification that there are no schedules waiting-->
      <v-row v-else-if="!showOverlay">
        <v-col cols="6" xs="10" class="mt-10">
          <v-alert type="warning">No schedules are waiting</v-alert>
        </v-col>
      </v-row>

    </v-row>

    <v-overlay :value="showOverlay" color="#B4B4B4">
      <v-progress-circular indeterminate size="50" color="amber"/>
    </v-overlay>

  </v-container>
</template>

<script>
import UserService from "@/service/UserService";
import ScheduleWorkerCard from "@/components/schedule/ScheduleWorkerCard";
import ScheduleService from "@/service/ScheduleService";

export default {
  name: "SchedulesWaitingComponent",
  components: {ScheduleWorkerCard},

  computed: {
    currentUserName() {
      return UserService.getCurrentUser().username;
    }
  },

  data() {
    return {
      showOverlay: true,
      errorMessage: null,

      schedules: [],
    }
  },

  created() {
    this.loadSchedules();
  },

  methods: {

    async loadSchedules() {
      this.showOverlay = true;
      try {
        this.schedules = await ScheduleService.getWaitingConfirmation();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.showOverlay = false;
      }
    },

    remove(arg) {
      if (arg != null && arg.scheduleId !== undefined) {
        const index = this.schedules.findIndex(prop => prop.id === arg.scheduleId);
        this.schedules.splice(index, 1);
      }
    }

  }
}

</script>
